<template>
  <div>
    <v-navigation-drawer
      v-model="showMenu"
      app
      fixed
    >
      <v-list dense>
        <!-- <v-list-item
          to="/settings"
        >
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Inställningar</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
          v-if="user.role === 'admin'"
          to="/listwo"
        >
          <v-list-item-action>
            <v-icon>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Importera</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.role === 'admin'"
          to="/users"
        >
          <v-list-item-action>
            <v-icon>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Användare</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/help">
          <v-list-item-action>
            <v-icon>help</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Hjälp</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      fixed
      dark
      color="primary"
    >
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu" />
      <v-toolbar-title>ME Vatten AB</v-toolbar-title>
      <v-spacer />
      <v-img
        :src="require('../assets/logo-notext.png')"
        class="my-3"
        contain
        max-width="40"
        height="40"
      />
    </v-app-bar>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'TopToolbar',

  data: () => ({
    showMenu: false
  }),

  computed: {
    ...sync('app', ['user'])
  },

  created () {
  }
}
</script>
