import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ApiService from './services/api.service'
import VueNotification from '@kugatsu/vuenotification'

ApiService.init()

Vue.config.productionTip = false
Vue.use(VueNotification, {
  timer: 5,
  showCloseIcn: true,
})

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load

  if (to.name === null) {
    next(false)
    return
  }
  
  if (store.state.app.token === '') {
    if (to.name !== 'nouse' && to.name !== 'authenticate') {
      if (from.name === 'nouse') {
        next(false)
        return
      }

      router.push('/nouse')
    }
  } else if (store.state.app.firstuse) {
    if (to.name !== 'firstuse') {
      if (from.name === 'firstuse') {
        next(false)
        return
      }
      
      router.push('/firstuse')
    }
  }
  next()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
