import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/nouse',
    name: 'nouse',
    component: () => import('../views/NoUseView.vue')
  },
  {
    path: '/firstuse',
    name: 'firstuse',
    component: () => import('../views/FirstUseView.vue')
  },
  {
    path: '/authenticate/:code',
    name: 'authenticate',
    component: () => import('../views/AuthenticateView.vue')
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('../views/QRCodeView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue')
  },
  {
    path: '/viewuser',
    name: 'viewuser',
    props: true,
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/newuser',
    name: 'newuser',
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/HelpView.vue')
  },
  {
    path: '/risku/:woid',
    name: 'risku',
    component: () => import('../views/RiskUView.vue')
  },
  {
    path: '/importwo',
    name: 'importwo',
    component: () => import('../views/ImportWorkordersView.vue')
  },
  {
    path: '/listwo',
    name: 'listwo',
    component: () => import('../views/ListWorkordersView.vue')
  },
  {
    path: '/viewwo/:woid',
    name: 'viewwo',
    component: () => import('../views/WorkorderView.vue')
  },
  {
    path: '/riskuresults',
    name: 'riskuresults',
    component: () => import('../views/RiskUResultsView.vue')
  },
  {
    path: '/riskuresult',
    name: 'riskuresult',
    component: () => import('../views/RiskUResultView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
