<template>
  <v-bottom-navigation
    v-model="activeItem"
    app
    fixed
    grow
    color="primary"
  >
    <v-btn
      value="home"
      to="/"
    >
      <span>Arbetsorder</span>
      <v-icon>home</v-icon>
    </v-btn>

    <v-btn
      v-if="user.role === 'admin'"
      value="risku"
      to="/riskuresults"
    >
      <span>RiskU Rapporter</span>
      <v-icon>fmd_bad</v-icon>
    </v-btn>

    <v-btn
      value="about"
      to="/about"
    >
      <span>Om</span>
      <v-icon>info</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'BottomNav',

  data: () => ({
    activeItem: {}
  }),

  computed: {
    ...sync('app', ['user'])
  },
}
</script>
