// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  version: '0.0.1',
  sysinfo: {},
  token: '',
  firstuse: true,
  user: {},
  workorder: {},
  risku: {},
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state)
}

const getters = {
  ...make.getters(state)
}

// console.log('app store module initialized ...')

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
